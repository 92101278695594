/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import { TOURNAMENTS_PAGE } from 'routes/routes.map';
import { updateProfile } from 'store/profile/reducer';
import { getURLWithoutParams } from 'utils/helpers';
import { useLazyQuery } from '@apollo/client';
import { getMemberProfile } from 'graphql/Queries';

interface IMember {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

const OAUTH_CONFIGS = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirectUri: window.location.origin + TOURNAMENTS_PAGE,
  response_type: 'code',
  code_challenge_method: 'S256',
  version: 2,
  scope: '',
  authEP: '/oauth/authorize',
  tokenEP: '/oauth/token',
  validateEP: '/api/token/validate',
  userEP: '/api/user',
};

const useAuthentication = () => {
  const {
    authEP,
    clientId,
    code_challenge_method,
    domain,
    response_type,
    scope,
    tokenEP,
    userEP,
    validateEP,
    redirectUri,
    version,
  } = OAUTH_CONFIGS;
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [tokenIsExpired, setTokenIsExpired] = useState(false);
  const [userId, setUserId] = useState('');
  const [userInfo, setUserInfo] = useState<any>({});
  const [loginResponse, setLoginResponse] = useState<any>({});
  const [jwt, setJwt] = useState({
    header: '',
    payload: '',
    signature: '',
  });

  const [getMember] = useLazyQuery(getMemberProfile);

  const login = () => {
    try {
      setStatus('Redirecting to login server for auth code');
      setJwt({
        header: '',
        payload: '',
        signature: '',
      });
      setLoginResponse({});
      setUserId('');
      setUserInfo({});
      setTokenIsValid(false);
      setTokenIsExpired(false);

      const stateLength = 40;
      const verifierLength = 128;

      // Create a random string for CSRF check
      const state = nanoid(stateLength);
      window.localStorage.setItem('oauth.state', state);

      // Create a random string for a code verifier
      const verifier = nanoid(verifierLength);
      window.localStorage.setItem('oauth.code_verifier', verifier);

      // Hash the code verifier to send as the code_challenge
      const challenge = removeInvalidCharacters(Base64.stringify(sha256(verifier)));

      // Redirect browser to auth server to request an auth code
      const args: any | null | undefined = new URLSearchParams();
      args.append('client_id', clientId);
      args.append('redirect_uri', redirectUri);
      args.append('response_type', response_type);
      args.append('scope', scope);
      args.append('state', state);
      args.append('code_challenge', challenge);
      args.append('code_challenge_method', code_challenge_method);
      args.append('v', version);

      window.localStorage.setItem('redirect_uri', window.location.href);
      window.location.href = `${domain}${authEP}?${args}`;
    } catch (error) {
      console.log(error);
    }
  };

  function removeInvalidCharacters(str) {
    return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  const callback = (code, state) => {
    setStatus('Exchanging auth code for access token');

    const expectedState = window.localStorage.getItem('oauth.state');
    const verifier = window.localStorage.getItem('oauth.code_verifier');

    // window.localStorage.removeItem('oauth.state');
    // window.localStorage.removeItem('oauth.code_verifier');

    if (!state || state !== expectedState) {
      setStatus('Invalid or missing state parameter');
      return;
    }

    axios
      .post(domain + tokenEP, {
        grant_type: 'authorization_code',
        client_id: clientId,
        redirect_uri: redirectUri,
        code_verifier: verifier,
        code: code,
      })
      .then((response: any) => {
        const { data } = response;
        setStatus('Access token granted');
        window.history.replaceState(null, document.title, redirectUri);
        setLoginResponse(response?.data);

        tokenUsage({
          accessToken: data.access_token,
          accessTokenType: data.token_type,
        });
      })
      .catch((error) => {
        setStatus('Error: ' + error.toString());
      });
  };

  const tokenUsage = ({
    accessTokenType,
    accessToken,
  }: {
    accessToken: string;
    accessTokenType: string;
  }) => {
    if (!accessTokenType || !accessToken) {
      setStatus('Error: Missing access token');
      return;
    }

    const jwt = accessToken?.split('.');
    const jwtHeader = JSON.parse(window.atob(jwt?.[0]) || '{}');
    const jwtPayload = JSON.parse(window.atob(jwt?.[1]) || '{}');

    const jwtSignature = jwt?.[2];

    setJwt({
      header: jwtHeader,
      payload: jwtPayload,
      signature: jwtSignature,
    });

    // This is the user ID. It is the same thing currently being stored in the UserId cookie
    setUserId(jwtPayload.sub);

    // Validate the token hasn't expired
    setTokenIsExpired(jwtPayload.exp * 1000 < Date.now());

    // Validate token signature, client, etc
    validateToken(accessTokenType + ' ' + accessToken).then((tokenIsValid) => {
      setTokenIsValid(tokenIsValid);
      if (tokenIsValid) {
        // Get more user info from the login API
        getUserInfo(accessTokenType + ' ' + accessToken);
      } else {
        setStatus('Invalid access token');
      }
    });
  };

  const validateToken = (auth) => {
    // Validate the token. Validates the token signature and expiration status, the OAuth client, the user, etc.
    // If everything is valid, this will return an HTTP code of 200. Otherwise it will return a 401.
    setStatus('Validating access_token');
    return axios
      .head(domain + validateEP, {
        headers: {
          Authorization: auth,
        },
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const getUserInfo = (auth) => {
    // Get more user info from the login API.
    // This is an OPTIONAL call that can be used at any point with the access token.
    // Making this call also validates the JWT token, including the signature, client, user, etc
    setStatus('Getting user info from login API');
    return axios
      .get(domain + userEP, {
        headers: {
          Authorization: auth,
        },
      })
      .then(async (response: any) => {
        setStatus('Successfully fetched user info from login API');
        const { data } = response;
        setUserInfo(data);

        const { id: memberId, email, first_name, last_name } = { ...data } as IMember;
        const token = auth.split(' ')[1];

        const oneMonth = 60 * 60 * 24 * 30;
        document.cookie = `UserId=${memberId};max-age=${oneMonth}`;

        const member = await getMember({
          variables: { member_number: memberId },
        });

        const currentMember = member?.data?.getUserProfileByMemberNumber;

        dispatch(
          updateProfile({
            ...data,
            email,
            first_name,
            last_name,
            memberId,
            memberName: `${first_name} ${last_name}`,
            total_masterpoints: currentMember.total_masterpoints,
            token,
          })
        );

        const redirectUrl = window.localStorage.getItem('redirect_uri');

        if (
          redirectUrl &&
          getURLWithoutParams(redirectUrl) !== getURLWithoutParams(window.location.href)
        ) {
          window.location.href = window.localStorage.getItem('redirect_uri') || '/';
          window.localStorage.removeItem('redirect_uri');
        }
        return currentMember;
      })
      .catch((error) => {
        setStatus('Error: ' + error.toString());
        return 'Code 401: Unauthorized';
      });
  };

  const initLogin = () => {
    if (window.location.search) {
      const args = new URLSearchParams(window.location.search);
      const code = args.get('code');
      const state = args.get('state');

      if (code && state) {
        callback(code, state);
      }
    }
  };

  return {
    status,
    userId,
    tokenIsExpired,
    tokenIsValid,
    userInfo,
    loginResponse,
    login,
    jwt,
    getUserInfo,
    initLogin,
    callback,
  };
};

export default useAuthentication;
